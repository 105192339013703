import React from 'react';
import { Row } from 'antd';
import { head, isEmpty, keyBy, orderBy, sortBy } from 'lodash';
import { getAllDescendantIds } from 'core/utils';
import { getCurrentUserPermissions } from 'redux/selectors/users';
import { getCommentsFilteredByPermission } from 'redux/selectors/comments';
import { useSelector } from 'react-redux';
import QuestionAppealComment from 'components/Checklist/ChecklistManager/components/QuestionAppealComment';
import ReviewComment from './Comment/CommentTypes/ReviewComment';

const CommentsList = ({
  comments = {},
  loading,
  onDelete,
  onUpdate,
  allowActions = true,
  showFirst,
  isQuestionView,
  appealObjection,
  appealComment,
  questionId,
  appealId,
  disabled,
  communication
}) => {
  const currentUserPermissions = useSelector(state => getCurrentUserPermissions(state));
  const { currentChecklist: uiCurrentChecklist } = useSelector(state => state.uiChecklistManager);
  const currentChecklist = isEmpty(uiCurrentChecklist) ? undefined : uiCurrentChecklist;

  const commentsByPermissions = getCommentsFilteredByPermission({
    comments: Object.values(comments),
    currentUserPermissions
  });

  const reviewComments = commentsByPermissions.filter(
    comment => comment.commentType === 'review_comment'
  );

  const reviewCommentsByIds = keyBy(sortBy(reviewComments, 'position'), 'id') || {};
  const commentsByIds = keyBy(commentsByPermissions, 'id');

  const reviewCommentsWithDescendantsIds = Object.keys(reviewCommentsByIds).map(reviewId => {
    return {
      ...commentsByIds[reviewId],
      descendantIds: getAllDescendantIds({ nodesByIds: commentsByIds, nodeId: reviewId }) || []
    };
  });

  const filteredComments = reviewCommentsWithDescendantsIds.filter(
    comment =>
      comment?.checklistDefinitionId === currentChecklist?.checklistDefinitionId ||
      isEmpty(comment?.checklistDefinitionId)
  );

  const getCommentsToRender = () => {
    return appealComment ? [appealComment, ...filteredComments] : filteredComments;
  };

  const commentsToRender = orderBy(getCommentsToRender(), 'position');

  const firstComment = head(commentsToRender);

  if (isEmpty(comments) || loading) return null;

  return (
    <Row align="end">
      {showFirst && firstComment ? (
        <>
          {firstComment?.commentType === 'appellant_comment' ? (
            <QuestionAppealComment
              key={firstComment?.id}
              appealObjection={appealObjection}
              appealComment={appealComment}
              questionId={questionId}
              appealId={appealId}
            />
          ) : (
            <ReviewComment
              disabled={disabled}
              onDelete={onDelete}
              onUpdate={onUpdate}
              key={firstComment?.id}
              reviewCommentId={firstComment?.id}
              allowActions={allowActions}
              isQuestionView={isQuestionView}
              communication={communication}
            />
          )}
        </>
      ) : (
        commentsToRender.map(comment => {
          if (comment?.commentType === 'appellant_comment')
            return (
              <QuestionAppealComment
                key={comment?.id}
                appealObjection={appealObjection}
                appealComment={appealComment}
                questionId={questionId}
                appealId={appealId}
              />
            );

          return (
            <ReviewComment
              disabled={disabled}
              onDelete={onDelete}
              onUpdate={onUpdate}
              key={comment?.id}
              reviewCommentId={comment?.id}
              descendantIds={comment?.descendantIds}
              allowActions={allowActions}
              isQuestionView={isQuestionView}
              communication={communication}
            />
          );
        })
      )}
    </Row>
  );
};

export default CommentsList;
