import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Col, Tooltip, Row, Radio, message } from 'antd';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RATING_MODE, WIDGET_VIEW_MODES } from 'core/utils/constants';
import { get, isEmpty, isEqual } from 'lodash';
import ExcelIcon from 'images/ExcelIcon';
import { useTranslation } from 'react-i18next';
import WidgetViewChanger from 'components/Dashboard/WidgetViewChanger';
import { LoadingOutlined } from '@ant-design/icons';
import { getChecklistDefinitionQuestionGroupsBeforeQuestions } from 'redux/selectors/checklistItems/checklistItems';
import { IconButton } from 'components/Buttons/IconButton';
import WidgetTable from 'components/Dashboard/WidgetTable';
import ClientInteractionDrawer from 'components/ClientInteractionDrawer/ClientInteractionDrawer';
import SCol from 'components/Standard/SCol';
import { TimePeriod } from 'components/TimePeriod';
import { WidgetTitle } from 'components/WidgetTitle';
import SRow from 'components/Standard/SRow';
import { commentsResource } from 'redux/resources/comments';
import EmptyWidget from '../../Components/EmptyWidget';
import { LargeWidgetCard } from '../../styled';
import SettingsModal from '../../Components/SettingsModal';
import {
  widgetNameSetter,
  widgetPeriodSetter,
  widgetInfoTextSetter,
  getSortString
} from '../../utils';
import { prepareColumns, prepareRows } from './prepareTableData';
import { useTableReviewsWithScoresExport } from './hooks';
import { ContextExportDashboards } from '../../DashboardPage';
import WidgetViewLastUpdate from '../../../../components/Dashboard/WidgetViewLastUpdate';

const isJsonStr = str => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

const TableReviewsWithScores = ({ widgetData, loadingWidgetId, isWarningWidget }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, type, filters, name } = widgetData;

  const { startExport, loadingExport } = useTableReviewsWithScoresExport();
  const [initialState, setInitialState] = useState({});
  const viewType = get(filters, 'viewType', WIDGET_VIEW_MODES.PERCENTAGE.value);
  const getOrganizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );

  const widgetsAnalytics = useSelector(state => state.dashboardAnalytics.byIds[id], isEqual);

  const usersByIds = useSelector(state => state.usersResource.byIds, shallowEqual);
  const unitsByIds = useSelector(state => state.unitsResource.byIds, shallowEqual);
  const commentsResourceByIds = useSelector(state => state.commentsResource.byIds);

  const parsedCommentsResourceByIds = Object.values(commentsResourceByIds).map(item => {
    const metadata = isJsonStr(item?.metadata) ? JSON.parse(item?.metadata) : item?.metadata;
    return {
      ...item,
      metadata
    };
  });

  const uploadedFilesResourceByIds = useSelector(state => state.uploadedFilesResource.byIds);

  const widgetValue = get(widgetsAnalytics, 'data', []);
  const widgetMeta = get(widgetsAnalytics, 'meta', []);

  const reviews = widgetValue.map(item => item?.review_id);

  const checklistDefinitionId = get(widgetData, 'filters.checklistDefinitionsIds');
  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    shallowEqual
  );

  const currentChecklist = get(checklistDefinitionsByIds, checklistDefinitionId, {});
  const checklistItems = useSelector(
    state => getChecklistDefinitionQuestionGroupsBeforeQuestions(state, currentChecklist),
    shallowEqual
  );

  // Подгрузка комментариев в стейт(commentsResource) которые относяться к текущему чеклисту и если ФО обновилась то подгружаем снова новые комментарии widgetsAnalytics
  useEffect(() => {
    dispatch(
      commentsResource.operations.loadCommentsByWidgetId({
        widget_id: id,
        organization_id: getOrganizationId
      })
    );
    // dispatch(
    //   commentsResource.operations.load({
    //     filters: {
    //       reviewsIds: reviews
    //     },
    //     include: 'author.unit,author.level,uploaded_files',
    //     pagination: false // Отключаем пагинацию чтобы загружались все комментарии
    //   })
    // );
  }, [widgetsAnalytics]);

  const tableData = {
    tableColumns: prepareColumns({
      widgetValue,
      currentChecklist,
      checklistItems,
      filters,
      widgetMeta
    }),
    tableRows: prepareRows({
      filters,
      currentChecklist,
      widgetValue,
      checklistItems,
      usersByIds,
      unitsByIds
    })
  };

  const onChangeViewType = viewType => {
    return dispatch(
      updateDashboardWidget({
        id,
        type,
        filters: { ...filters, viewType }
      })
    );
  };

  const onChangeViewMode = ({ viewMode }) => {
    return dispatch(
      updateDashboardWidget({
        id,
        type,
        filters: { ...filters, viewMode }
      })
    );
  };

  const onChange = async ({ pagination, sorter }) => {
    setInitialState({
      number: pagination?.current,
      size: pagination?.pageSize
    });

    const columnsKeys = tableData.tableColumns.map(item => item.key);
    const { columnKey } = sorter;

    const isNewSort = columnsKeys.includes(columnKey);

    await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters,
        page: {
          number: pagination.current,
          size: pagination.pageSize
        },
        sort: isNewSort ? getSortString({ sorter, viewMode: filters?.viewMode }) : ''
      })
    );
  };
  const { viewMode } = filters;
  const itemsType =
    viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
      ? 'checklist-questions'
      : 'checklist-question-groups';

  const targetItems = checklistItems.filter(item => item.type === itemsType);

  const columns = [
    {
      title: t('general.clientInteractionType'),
      dataIndex: 'reviewId',
      key: 'reviewId'
    },
    {
      title: t('general.operator'),
      dataIndex: 'operatorId',
      key: 'operatorId'
    },
    {
      title: t('general.unit'),
      dataIndex: 'unitName',
      key: 'unitName'
    },
    {
      title: t('general.phoneNumber'),
      dataIndex: 'communicationKey',
      key: 'communicationKey'
    },
    {
      title: t('general.clientInteractionDate'),
      dataIndex: 'clientInteractionStartedAt',
      key: 'clientInteractionStartedAt'
    },
    {
      title: t('general.reviewDate'),
      dataIndex: 'createdAt',
      key: 'createdAt'
    },
    ...targetItems.map(item => {
      return {
        title: item?.name,
        dataIndex: item?.id,
        key: item?.id
      };
    }),
    {
      title: t('general.result'),
      dataIndex: 'checklistScore',
      key: 'checklistScore'
    }
  ];
  const isExport = true;
  const body = {
    widgetType: type,
    widgetData: {
      columns,
      rows: prepareRows({
        isExport,
        filters,
        currentChecklist,
        widgetValue,
        checklistItems,
        usersByIds,
        unitsByIds
      })
    }
  };
  const isEmptyWidgetData = isEmpty(widgetData);
  const { addElementExportWidget } = useContext(ContextExportDashboards);
  const prevBodyRef = useRef(null);

  useLayoutEffect(() => {
    if (prevBodyRef.current !== body && (!isEmptyWidgetData && !isWarningWidget)) {
      prevBodyRef.current = body;
      addElementExportWidget({ ...prevBodyRef.current, id });
    }
  }, [body]);
  return (
    <>
      {isEmptyWidgetData || isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={24} key={id}>
          <LargeWidgetCard>
            <Row type="flex" justify="space-between" align="top">
              <SCol span={8}>
                <SRow type="flex" gutter={[6, 0]} justify="start" alignItems="center" wrap={false}>
                  <SCol flex="auto" display="flex" alignItems="center">
                    <Tooltip title={widgetNameSetter({ name, type })}>
                      <WidgetTitle>{widgetNameSetter({ name, type })}</WidgetTitle>
                    </Tooltip>
                    {widgetInfoTextSetter({ type })}
                  </SCol>
                </SRow>

                <TimePeriod type="secondary">
                  {widgetPeriodSetter({
                    widgetsFilters: filters,
                    widgetType: type,
                    showDateMode: false
                  })}
                </TimePeriod>
              </SCol>
              <Col span={6}>
                <Row justify="center">
                  <Col>
                    <Radio.Group
                      onChange={e => onChangeViewMode({ viewMode: e.target.value })}
                      value={filters.viewMode || WIDGET_VIEW_MODES.QUESTIONS.value}
                    >
                      <Radio.Button value={WIDGET_VIEW_MODES.QUESTIONS.value}>
                        {t(WIDGET_VIEW_MODES.QUESTIONS.title)}
                      </Radio.Button>
                      <Radio.Button
                        value={WIDGET_VIEW_MODES.GROUPS.value}
                        disabled={!currentChecklist?.isGroupable}
                      >
                        {t(WIDGET_VIEW_MODES.GROUPS.title)}
                      </Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
              <SCol display="flex" justifyContent="flex-end" span={8} alignItems="center">
                <WidgetViewChanger
                  value={viewType}
                  onChange={onChangeViewType}
                  disabled={currentChecklist.ratingMode === RATING_MODE.NUMBERS}
                />
                <IconButton
                  tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                  button={{
                    icon: loadingExport ? <LoadingOutlined spin /> : <ExcelIcon />,
                    marginTop: '2px',
                    onClick: () =>
                      widgetMeta.total_count >= 5000
                        ? message.warning(t('messages.warning.limitExportReviews'))
                        : startExport({
                            id,
                            type,
                            filters,
                            widgetMeta,
                            initialState,
                            checklistItems,
                            currentChecklist,
                            commentsResourceByIds: parsedCommentsResourceByIds,
                            uploadedFilesResourceByIds
                          }),
                    size: 'icon'
                  }}
                />
                <SettingsModal widgetData={widgetData} />
                <WidgetViewLastUpdate
                  widgetData={widgetData}
                  dateLastUpdate={widgetsAnalytics?.dateLastUpdate}
                />
              </SCol>
            </Row>
            <WidgetTable
              columns={tableData.tableColumns.filter(item => item.key !== 'unitName')}
              dataSource={tableData.tableRows}
              loading={loadingWidgetId === id || loadingExport}
              size="small"
              bordered
              showSorterTooltip={false}
              scroll={{ x: 1500, y: 452 }}
              style={{ marginTop: 8 }}
              rowClassName={record => (record.key === 'header' ? 'table-row-light' : '')}
              pagination={{
                position: 'bottomLeft',
                showSizeChanger: true,
                total: widgetMeta.total_count,
                current: widgetMeta.page
              }}
              onChange={(pagination, filters, sorter) => onChange({ pagination, sorter })}
            />
            <ClientInteractionDrawer shouldLoad />
          </LargeWidgetCard>
        </Col>
      )}
    </>
  );
};

export default TableReviewsWithScores;
